.floatingButtonContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.floatingButton {
    background-color: #e44700; 
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}
  
.floatingButton:hover {
    background-color: #cc3d00;
    transform: scale(1.1);
}

.blinkingIcon {
    animation: blinking 1s infinite;
    margin-right: 10px;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

/* modal start */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.modal h2 {
    margin-bottom: 25px;
    font-size: 26px;
    color: #333333;
}

.paymentOptions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.paymentOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.paymentOption:hover {
    background-color: #f4f4f4;
    transform: scale(1.05);
}

.paymentOption .icon {
    font-size: 50px;
    margin-bottom: 15px;
    color: #e44700;
}

.paymentOption p {
    margin: 0;
    font-size: 20px;
    color: #333333;
    font-weight: bold;
}

/* COD form start */
.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center; /* Center the form content */
}

.formGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px; /* Ensure the form is centered and not too wide */
}

.formGroup label {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
}

.formGroup input, .formGroup textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 400px; /* Ensure buttons are aligned and not too wide */
}

.backButton, .submitButton {
    background-color: #e44700;
    color: #ffffff;
    border: none;
    padding: 12px 25px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    text-align: center;
}

.backButton:hover, .submitButton:hover {
    background-color: #cc3d00;
    transform: scale(1.05);
}

.closeButton {
    background-color: #e44700;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px auto 0;
}

.closeButton:hover {
    background-color: #cc3d00;
}

/* Media queries for mobile view */
@media (max-width: 600px) {
    .modal {
        padding: 20px;
    }
    
    .formGroup {
        width: 90%;
    }

    .buttonGroup {
        flex-direction: column;
        gap: 10px;
    }

    .backButton, .submitButton {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }
}
