   body {
    background-color: #e44700; 
    font-family: Arial, sans-serif;
    color: #000000; 
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff; 
   
    color: #000000;
    width: 100%; 
    box-sizing: border-box; 
    margin: 0; 
  }
  
  .header {
    width: 100%;
    background-color: #e44700; 
    padding: 10px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; 
  }
  
  .logoImage {
    width: 50px; 
    margin-right: 10px;
  }


  
  .logoText {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff; 
  }
  
  
   .headlineSection {
    
    max-width: 800px;
    margin: 20px 0;
    padding: 60px 20px; 
    background: linear-gradient(135deg, #ff7f50, #ff4500); 
    border-radius: 12px; 
    text-align: center;
    color: #ffffff; 
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  }
  
  .headlineContent {
    position: relative;
    z-index: 2;
  }
  
  .headlineSection h1 {
    font-size: 3em; 
    margin-bottom: 20px;
    font-weight: bold; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
  }
  
  .headlineSection p {
    font-size: 1.5em; 
    margin-bottom: 0;
    font-weight: 300; 
    line-height: 1.4;
  }

  

  .subHeadlineSection {
  
    max-width: 800px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 8px;
    text-align: center;
    color: #e44700; 
  }
  
  .imageSection {
  
    max-width: 800px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageContainer {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .imageContainer:hover {
    transform: scale(1.05);
  }
  
  .imageContainer img {
    width: 100%;
    height: auto;
  }
  
  .imageDescription {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }
  
  .imageDescription h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .imageDescription p {
    margin: 0;
    font-size: 14px;
  }
  
  .ctaButton {
    background-color: #e44700; 
    color: #ffffff;
    border: none;
    padding: 15px 30px; 
    font-size: 20px; 
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease; 
  }
  
  .ctaButton:hover {
    background-color: #cc3d00; 
    transform: scale(1.05); 
  }
  
  .ctaContent {
    text-align: center;
    background: linear-gradient(135deg, #e44700, #ff6600); 
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff; 
  }
  
  .ctaContent h2 {
    margin-bottom: 10px;
    font-size: 2em;
  }
  
  .ctaContent p {
    margin-bottom: 20px;
    font-size: 1.2em;
  }

  .blinkingHand {
    animation: blinking 1s infinite;
    margin-right: 10px;
  }
  
  @keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  

  
  img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .faqItem {
    text-align: left;
    margin: 10px 0;
  }
  
  .valuePropositionSection {
    max-width: 800px;
    margin: 40px 0; 
    padding: 40px 20px;
    background-color: #f4f4f4; 
    border-radius: 12px;
    text-align: center;
    color: #000000; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  }
  
  .valuePropositionSection h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #e44700; 
  }
  
  .valuePropositionList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .valuePropositionList li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #333333; 
  }
  
  .productFeatureAndBenefitsSection {
    max-width: 800px;
    margin: 40px 0; 
    padding: 40px 20px;
    background-color: #ffffff; 
    border-radius: 12px;
    text-align: center;
    color: #000000; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  }
  
  .productFeatureAndBenefitsSection h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #e44700; 
  }
  
  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .featureGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .featureItem {
    background: #f4f4f4; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: center;
  }

  .featureIcon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .featureText h3 {
    margin: 10px 0;
    font-size: 1.5em;
    color: #e44700; 
  }

  .featureText p {
    font-size: 1em;
    color: #555555;
  }

  .socialProofSection {

    max-width: 800px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 8px;
    text-align: center;
    color: #000000; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .socialProofSection h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #e44700; 
  }
  
  .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .testimonialItem {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;
    width: 100%;
    position: relative;
  }
  
  .customerImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  blockquote {
    font-size: 1.2em;
    margin: 0 0 10px;
    color: #555555;
  }
  
  .testimonialItem p {
    margin: 0;
    font-size: 1em;
    color: #999999;
  }

  

  .detailedProductInformationSection {

    max-width: 800px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 8px;
    text-align: center;
    color: #000000; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .detailedProductInformationSection h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #e44700; 
  }
  
  .productInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .productImage {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
  }
  
  .productDetails {
    text-align: left;
    max-width: 600px;
  }
  
  .productDetails p {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  .productDetails strong {
    color: #e44700; 
  }



  .accordionItem {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #e44700;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .accordionButton {
    width: 100%;
    padding: 15px;
    background-color: #e44700;
    color: #ffffff;
    text-align: left;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
  }
  
  .accordionButton:hover {
    background-color: #cc3d00;
  }
  
  .accordionContent {
    padding: 15px;
    background-color: #ffffff;
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
  }

  @media screen and (min-width: 768px){
    section {
      width: 50%;
      margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 600px) {
    .valuePropositionSection,
    .productFeatureAndBenefitsSection {
      padding: 30px 15px; 
    }
  
    .valuePropositionSection h2,
    .productFeatureAndBenefitsSection h2 {
      font-size: 2em;
    }
  
    .valuePropositionList li,
    .featureText p {
      font-size: 1em; 
    }
  
    .icon {
      width: 30px;
      height: 30px;
    }
  
    .featureIcon {
      width: 80px; 
      height: 80px;
    }
    
    
    .logoImage {
        padding-left: 10px;
      }

      
    .headlineSection {
        margin: 10px 0 !important; 
    }

    .valuePropositionList li {
      font-size: 1em;
    }
  
    .icon {
      width: 30px;
      height: 30px;
    }

    .headlineSection,
    .subHeadlineSection,
    .valuePropositionSection,
    .callToActionSection,
    .productFeatureAndBenefitsSection,
    .socialProofSection,
    .detailedProductInformationSection,
    .pricingInformationSection,
    .faqSection {
      padding: 15px;
      margin: 0; 
    }
  
    .ctaButton {
      padding: 10px 20px; 
      font-size: 18px; 
    }
    .ctaContent {
      position: relative;
    }
    
    .ctaContent h2 {
      font-size: 2.5em;
      margin-bottom: 20px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    }
    
    .ctaContent p {
      font-size: 1.5em;
      margin-bottom: 30px;
      font-weight: 300;
      line-height: 1.4;
    }
    
    .ctaButton {
      background-color: #e44700; 
      color: #ffffff;
      border: none;
      padding: 15px 30px; 
      font-size: 20px; 
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease, transform 0.3s ease; 
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .ctaButton:hover {
      background-color: #cc3d00; 
      transform: scale(1.05); 
    }  
    
    .floatingButton {
      padding: 10px 20px;
      font-size: 18px;
    }
    


    .testimonialItem {
        padding: 15px;
      }
    
      .customerImage {
        width: 50px;
        height: 50px;
      }
    
      blockquote {
        font-size: 1em;
      }
    
      .testimonialItem p {
        font-size: 0.9em;
      }



      .productInfo {
        flex-direction: column;
        align-items: center;
      }
    
      .productImage {
        max-width: 100%;
      }
    
      .productDetails {
        text-align: center;
      }
  }
  
